.unicorn__years {
	width: 100%;

	.years {
		width: 100%;

		&__header {
			width: 100%;
		}

		&__view-all-button {
			border: 0;
			margin: 0;
			padding: 0;
			opacity: 0;
			transform: translateY(-15px);
			background-color: transparent;
			transition: all 0.3s ease;
			animation: unicornFadeIn 0.3s ease-in forwards;
		}

		&__display {
			margin: 0;
			padding: 10px 10px 0;
			color: $c-unicorn-grey;
			font-size: 30px;
			cursor: pointer;
			transition: all 0.3s ease;

			&:hover {
				color: $c-unicorn-black;
			}
		}

		&__selected {
			font-weight: 500;
			color: $c-unicorn-selected;

			&:hover {
				color: $c-unicorn-selected;
				transform: scale(1.05);
			}
		}

		&__nav {
			width: 100%;
			margin: 0;
			padding: 0;
			overflow: hidden;
			display: flex;
			justify-content: space-between;

			@for $i from 0 through 7 {
				.years {
					/* ---- */
					&__nav-item {
						/* ---- */
						&:nth-child(#{$i}n) {
							animation-delay: #{$i * 0.05}s;
						}
					}
				}
			}
		}

		&__nav-item {
			width: calc(100% / 7);
			padding: 10px 8px;
			opacity: 0;
			list-style-type: none;
			transition: all 0.3s ease;
			transform: translateY(-15px);
			animation: unicornFadeIn 0.3s ease-in forwards;
			flex-shrink: 0;
		}

		&__nav-button {
			width: 100%;
			padding: 0;
			margin: 0;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			&:disabled {
				/* ---- */
				.years {
					/* ---- */
					&__text {
						opacity: 0.35;
						cursor: default;
						text-decoration: line-through;
					}
				}
			}

			&--next {
				transform: rotate(180deg);
			}
		}

		&__text {
			margin: 0;
			color: $c-unicorn-grey;
			font-size: 16px;
			transition: all 0.3s ease;

			&:hover {
				color: $c-unicorn-black;
				font-weight: 500;
			}
		}

		&__today {
			color: $c-unicorn-orange;
			font-weight: 500;
		}

		&__text-selected {
			font-weight: 500;
			color: $c-unicorn-selected;

			&:hover {
				color: $c-unicorn-selected;
				font-weight: 500;
				transform: scale(1.05);
			}
		}

		&__selection {
			position: absolute;
			width: 100%;
			top: 55px;
			left: 0;
			bottom: 0;
			margin: 0;
			padding: 0;
			z-index: 9999999;
			background-color: $c-unicorn-white;
			border: 1px solid $c-unicorn-border;
			display: flex;
			flex-wrap: wrap;

			@for $i from 0 through 30 {
				.years__visible:nth-child(#{$i}n) {
					animation-delay: #{$i * 0.05}s;
				}
			}
		}

		&__selection-item {
			width: calc(100% / 5);
			padding: 10px;
			opacity: 0;
			list-style-type: none;
			transition: all 0.3s ease;
			transform: translateY(-15px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__selection-button {
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			border: 0;
			cursor: pointer;
			background-color: transparent;

			&:disabled {
				cursor: default;

				.years {
					/* ---- */
					&__text {
						opacity: 0.35;
						text-decoration: line-through;
					}
				}
			}
		}

		&__selection-prev,
		&__selection-next {
			svg {
				width: 15px;
				height: 15px;
			}
		}

		&__selection-next {
			transform: rotate(180deg);
		}

		&__visible {
			animation: unicornFadeIn 0.1s ease-in forwards;
		}
	}
}
