.app-page {
	height: 100%;

	> div {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}

.app-loading {
	svg {
		width: 200px;

		path {
			stroke: #eaeaea;
			stroke-dasharray: 500;
			animation: loading 5s linear infinite;
		}
	}
}

@keyframes loading {
	to {
		stroke-dashoffset: 1000;
	}
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}
