.unicorn-dates {
	/* ---- */
	.dates {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		@for $i from 0 through 42 {
			&__date:nth-child(#{$i}n) {
				animation-delay: #{$i * 0.05}s;
			}
		}

		&__date {
			width: calc(100% / 7);
			aspect-ratio: 1;
			padding: 10px;
			border: 0;
			opacity: 0;
			transform: scale(0.5) translateY(-25px);
			background-color: transparent;
			animation: unicornCellFadeIn 0.1s ease-in forwards;

			&:disabled {
				cursor: default;

				.dates {
					/* ---- */
					&__inner {
						/* ---- */
						&:hover {
							/* ---- */
							.dates {
								/* ---- */
								&__text {
									color: $c-unicorn-grey;
								}
							}
						}
					}

					&__text {
						opacity: 0.35;
						text-decoration: line-through;
					}

					&__backdrop-selected {
						opacity: 0.35;
						box-shadow: none;
						background-color: $c-unicorn-border;

						.dates {
							/* ---- */
							&__text {
								opacity: 1;
								color: $c-unicorn-grey;
							}
						}
					}
				}
			}
		}

		&__inner {
			height: 100%;
			width: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				/* ---- */
				.dates {
					/* ---- */
					&__text {
						color: darken($c-unicorn-offset-date, 10%);
						font-weight: 500;
					}

					&__same-month {
						color: $c-unicorn-black;
						font-weight: 500;
					}

					&__backdrop-selected {
						transform: scale(1.1);

						.dates {
							/* ---- */

							&__text {
								color: $c-unicorn-white;
							}
						}
					}
				}
			}
		}

		&__backdrop {
			width: 35px;
			height: 35px;
			border-radius: 35px;
			transition: all 0.3s ease;
			display: flex;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
		}

		&__backdrop-selected {
			background-color: $c-unicorn-selected;
			box-shadow: rgba(223, 209, 255, 1) 0px 5px 15px;

			.dates {
				/* ---- */
				&__text {
					color: $c-unicorn-white;
				}
			}
		}

		&__text {
			margin: 0;
			color: $c-unicorn-offset-date;
			transition: all 0.3s ease;
		}

		&__same-month {
			color: $c-unicorn-grey;
		}

		&__today {
			color: $c-unicorn-orange;
			font-weight: 500;
		}
	}
}
