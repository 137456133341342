.page-dashboard {
	@extend .app-page;

	.dashboard {
		//
		&__header {
			position: fixed;
			top: 105px;
			right: 0;
			z-index: 9999;
			margin-left: auto;
			pointer-events: none;
		}

		&__header-wrapper {
			overflow: hidden;

			.app-button {
				margin: 0 15px 10px 0;
				padding: 10px 50px 10px 10px;
				border: 1px solid $c-primary;
				transition: 0.5s cubic-bezier(0.1, 0.7, 0.6, 0.9);
				transform: translateX(calc(100% - 30px));
				box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
				pointer-events: auto;
				flex-direction: row-reverse;

				&:hover {
					transform: translateX(50px);

					.app-button {
						//
						&__label {
							opacity: 1;
						}
					}
				}

				&__label {
					opacity: 0;
					transition: $transition;
				}

				&__icon {
					width: 25px;
					height: 25px;
					margin: 0 10px 0 0;
					border-radius: 25px;

					&--add {
						border: 1px solid $c-white;

						svg {
							width: 100%;
							height: 100%;

							path {
								stroke: $c-white;
							}
						}
					}

					&--upload {
						border: 1px solid $c-primary;

						svg {
							width: 100%;
							height: 100%;

							path {
								stroke: $c-primary;
							}
						}
					}
				}
			}
		}

		&__send-all-invoices {
			margin: 15px 0 20px;
		}

		&__scheduler {
			display: flex;
			margin-left: auto;
			padding-right: 15px;
		}

		&__info {
			width: 20px;
			height: 20px;
			margin: 0;
			border-radius: 25px;
			background-color: $c-dark-grey;
			color: $c-white;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			transition: $transition;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				background-color: $c-black;
			}
		}

		&__switch {
			border-radius: 50px;
			display: inline-flex;
			align-items: center;

			.MuiSwitch-root {
				padding: 5px;
				transform: scale(0.8);

				.Mui-checked {
					.MuiSwitch-thumb {
						background-color: $c-white;
					}

					& ~ .MuiSwitch-track {
						background-color: $c-green !important;
					}
				}

				.MuiSwitch-thumb {
					background-color: $c-primary;
				}

				.MuiSwitch-track {
					border-radius: 100px;
					background-color: #f6fbff;
					border: 1px solid $c-primary;
					opacity: 1;
				}
			}
		}

		.app-table {
			//
			.table {
				//
				&__th {
					//
					&:last-child {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

				&__td {
					//
					&:last-child {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
}

.app-dashboard-menu {
	//
	.MuiPaper-root {
		border-radius: 5px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		.MuiMenu-list {
			padding: 0;

			.MuiMenuItem-root {
				padding: 10px 20px;
				color: $c-black;
				font-size: $text-size-s;
			}
		}
	}
}
