.app-side-nav {
	height: 100%;
	width: $side-nav-width;
	transition: $transition;
	background-size: cover;
	background-position: left;
	background-repeat: no-repeat;
	background-image: url("../../assets/images/components/app-side-nav/background.jpg");
	display: flex;
	align-items: stretch;
	flex-direction: column;

	@include max-width($scr-sm) {
		position: fixed;
		width: 0;
		z-index: 1299;
	}

	.side-nav {
		padding: 25px 0;
		flex: 1;
		display: flex;
		align-items: stretch;
		flex-direction: column;

		&__header {
			display: flex;
			align-items: center;
		}

		&__menu {
			position: relative;
			width: 50px;
			height: 50px;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("../../assets/images/components/app-side-nav/close-icon.svg");
			background-color: transparent;
			border: 0;
			z-index: 0;
			cursor: pointer;
			flex-shrink: 0;
			display: none;
		}

		&__logo {
			width: 150px;
			height: 50px;
			margin: 0 auto;
			cursor: pointer;
			transition: $transition;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(../../assets/images/atf-logo.png);
			border: 0;
			background-color: transparent;

			@include max-width($scr-sm) {
				width: 0;
			}
		}

		&__nav {
			position: relative;
			overflow-x: hidden;
			overflow-y: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			flex: 1;
			display: flex;
			flex-direction: column;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__list {
			padding: 0;
			transition: $transition;
		}

		&__item {
			position: relative;
			width: 100%;
			padding: 0;
			margin: 5px 0;
			min-width: 200px;
			cursor: pointer;
			z-index: 999;
			pointer-events: none;
			list-style-type: none;
			border: 0;
			background-color: transparent;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: $transition;

			&--logout {
				margin-top: auto;
				padding: 0 0 0 40px;
			}
		}

		&__wrapper {
			height: 50px;
			color: $c-white;
			text-decoration: none;
			white-space: nowrap;
			pointer-events: auto;
			display: flex;
			align-items: center;
		}

		&__content {
			padding: 0 0 0 40px;
			display: flex;
			flex-direction: row;
			align-items: center;
			transition: $transition;
		}

		&__icon {
			width: 23px;
			height: 23px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			svg {
				width: 100%;
				height: 100%;
				flex-shrink: 0;
			}

			.dashboard-icon {
				width: 16px;
				height: 16px;
			}
		}

		&__children {
			width: 240px;
			margin: 15px 0;
			padding: 0 23px;
			pointer-events: auto;

			&:hover {
				.side-nav__label {
					color: $c-yellow;
				}
			}
		}

		&__label {
			margin: 2.5px 0 0 10px;
			color: $c-white;
			pointer-events: none;
			white-space: pre-line;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
			transition: $transition;

			&--active {
				color: $c-primary;
			}

			&--child-active {
				color: $c-yellow;
			}
		}

		&__background {
			position: absolute;
			height: 50px;
			width: 100%;
			top: 26px;
			right: 0;
			left: 25px;
			border-top-left-radius: 40px;
			border-bottom-left-radius: 40px;
			background-color: $c-background;
			transition: $transition;
			z-index: 888;

			&:before,
			&:after {
				content: "";
				position: absolute;
				background-color: transparent;
				right: 25px;
				height: 35px;
				width: 15px;
				transition: $transition;
			}

			&:before {
				bottom: -35px;
				border-top-right-radius: 15px;
				box-shadow: 0 -15px 0 0 $c-background;
			}

			&:after {
				top: -35px;
				border-bottom-right-radius: 15px;
				box-shadow: 0 15px 0 0 $c-background;
			}
		}
	}

	&--shrinked {
		width: $side-nav-shrinked-width;

		@include max-width($scr-sm) {
			width: 100%;

			.side-nav {
				//
				&__logo {
					width: 150px;
				}

				&__menu {
					display: block;
				}

				&__background {
					left: 7px;

					&:before,
					&:after {
						height: 35px;
						width: 15px;
						right: 7px;
					}

					&:before {
						bottom: -35px;
					}

					&:after {
						top: -35px;
					}
				}

				&__content {
					padding: 0 0 0 18px;
					align-items: center;
				}

				&__item {
					//
					&--logout {
						padding: 0 0 0 20px;
					}
				}
			}
		}

		@include min-width($scr-sm) {
			.side-nav {
				&__logo {
					width: 45px;
					height: 50px;
					background-image: url(../../assets/images/atf-icon.png);
				}

				&__item {
					//
					&--logout {
						padding: 0 0 0 15px;
					}
				}

				&__content {
					padding: 0 0 0 18px;
					align-items: center;

					&:before {
						display: block;
						position: fixed;
						width: 0;
						opacity: 0;
						margin-left: 35px;
						color: $c-white;
						font-size: $text-size-xs;
						border-radius: 100px;
						background-color: $c-black;
						transition: $transition;
						pointer-events: none;
						z-index: -10;
						content: attr(data-content);
					}

					&:hover {
						&:before {
							width: auto;
							padding: 10px 15px;
							opacity: 1;
							z-index: 1;
						}
					}
				}

				&__label {
					opacity: 0;
				}

				&__background {
					left: 7px;

					&:before,
					&:after {
						height: 35px;
						width: 15px;
						right: 7px;
					}

					&:before {
						bottom: -35px;
					}

					&:after {
						top: -35px;
					}
				}
			}
		}
	}
}
