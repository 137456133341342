.app-button {
	min-width: 100px;
	border: 0;
	padding: 10px 20px;
	border-radius: 100px;
	background-color: $c-primary;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: $transition;

	&:disabled {
		border: 1px solid $c-disabled;
		background-color: $c-disabled;

		.app-button__label {
			color: #828a8f;
		}
	}

	&--large {
		padding: 15px 20px;
	}

	&--outline {
		background-color: $c-white;
		border: 1px solid #41657b;

		.app-button__label {
			color: #41657b;
		}

		&:hover {
			border: 1px solid darken(#41657b, 15%);

			.app-button__label {
				color: darken(#41657b, 15%);
			}
		}
	}

	&__label {
		margin: 0;
		color: $c-white;
		font-weight: $text-weight-normal;
		font-size: $text-size-s;
	}

	.app-icon {
		width: 15px;
		height: 15px;
		margin: 0 0 0 5px;
	}
}
