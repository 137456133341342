.app-alert {
	position: fixed;
	margin: 0 10px;
	top: 70px;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 9999;
	transition: $transition;

	.alert {
		opacity: 0;
		padding: 10px 20px;
		border: 1px solid $c-primary;
		border-radius: 100px;
		background-color: #ddeff5;
		transition: $transition;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&__icon {
			width: 18px;
			height: 18px;
			margin: 0 5px;
		}

		&__text {
			margin: 0;
			color: $c-primary;
			font-weight: $text-weight-semibold;
			font-size: $text-size-xs;
		}
	}

	&--active {
		.alert {
			opacity: 1;
		}
	}

	&--error {
		.alert {
			border: 1px solid $c-red;
			background-color: #f8d7da;

			&__text {
				color: $c-red;
			}
		}
	}
}
