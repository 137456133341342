.page-invoice-create-edit {
	@extend .app-page;

	.invoice-create-edit {
		//
		&__form {
			width: 100%;
			max-width: 1280px;
			padding: 0 15px 0 0;
		}

		&__container {
			margin: 15px 0;
			gap: 15px;
			display: flex;
		}

		&__name {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-weight: $text-weight-semibold;
		}

		&__date {
			margin: 5px 0;
			color: $c-grey;
			font-size: $text-size-xs;
		}

		&__label {
			margin: 0;
			color: $c-tertiary;
			font-weight: $text-weight-semibold;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__delete {
			width: 30px;
			height: 30px;
			color: $c-red;
			font-size: $text-size-xs;
			border: 1px solid $c-red;
			border-radius: 30px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__box {
			flex: 1;
			padding: 15px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__box-header {
			min-height: 40px;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			margin-top: 10px;
			gap: 25px;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
		}

		&__row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 20px 35px;

			&--divider {
				padding-bottom: 25px;
				border-bottom: 1px dashed $c-disabled;
			}

			.app-input,
			.app-mobile-input,
			.app-select-input,
			.app-calendar-input-2 {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			.app-input--multiline {
				width: 100%;
			}
		}

		&__column {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 20px 35px;

			.app-input {
				@include min-width($scr-sm) {
					width: calc(50% - 17.5px);
				}
			}

			.app-input--multiline {
				width: 100%;
			}
		}

		&__input {
			width: 100%;
			
			.app-input {
				//
				&__label {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
			}

			@include min-width($scr-sm) {
				width: calc(50% - 17.5px);

				.app-input {
					width: 100%;
				}
			}
		}

		&__add-more-button {
			margin-left: auto;

			.app-button {
				padding: 5px 10px;
				border: 1px solid $c-green;

				&__label {
					color: $c-green;
					font-size: $text-size-xs;
				}

				&:hover {
					border: 1px solid $c-green;

					.app-button__label {
						color: $c-green;
					}
				}
			}
		}

		&__send-email {
			display: flex;
			align-items: center;
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin-top: 25px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}

		.MuiSwitch-root {
			padding: 0;
			width: 28px;
			height: 18px;
			margin: 5px 10px 5px 0;
			border-radius: 100px;

			.MuiSwitch-switchBase {
				padding: 3px;
			}

			.Mui-checked {
				color: $c-primary;
				transform: translateX(10px);

				+ .MuiSwitch-track {
					opacity: 1;
					background-color: $c-primary;
				}

				.MuiSwitch-thumb {
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-size: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNTA1NjJMMy4zNDMxNSA3TDkgMSIgc3Ryb2tlPSIjMDE4ODk3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
					}
				}
			}

			.MuiSwitch-thumb {
				width: 12px;
				height: 12px;
				color: $c-white;
			}
		}
	}
}
