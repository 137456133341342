.page-invoice-bulk-upload {
	@extend .app-page;

	.invoice-bulk-upload {
		//
		&__title {
			margin: 0;
			color: $c-blue;
			font-size: $text-size-m;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}

		&__header {
			margin: 20px 15px;

			.app-button {
				margin-left: auto;
			}
		}

		&__body {
		}

		&__table {
			margin: 20px 0;
		}

		&__drop {
			height: 100%;
			min-height: 300px;
			margin: 20px 15px 20px 0;
			padding: 25px;
			border-radius: 8px;
			background-color: $c-white;
			border: 2px dashed $c-border;
			cursor: pointer;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			&--dragover {
				border: 2px dashed $c-green;
			}
		}

		&__icon {
			width: 40px;
			height: 40px;
			border-radius: 40px;
			border: 1px dashed $c-green;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__placeholder {
			color: $c-grey;
			font-size: $text-size-s;
		}

		&__button-container {
			width: 100%;
			max-width: 350px;
			margin: 25px 15px 25px;
			margin-left: auto;
			gap: 15px;
			display: flex;

			.app-button {
				flex: 1;

				&--outline {
					width: auto;
					border: 0;
					background-color: transparent;

					.app-button__label {
						color: $c-primary;
					}
				}
			}
		}
	}
}
