.app-table {
	width: 100%;

	&--extend {
		height: 100%;
	}

	&__container {
		height: 100%;
		padding: 0 15px 0 0;
		overflow-y: hidden;
		overflow-x: scroll;
		display: flex;
		flex-direction: column;
	}

	.table {
		width: 100%;
		border-radius: 10px;
		background-color: $c-white;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		transition: $transition;
		overflow: hidden;

		&--extend {
			height: 100%;
		}

		&__thead {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			background-color: $c-light-grey;
		}

		&__tbody {
		}

		&__th {
			padding: 20px;
			text-align: left;
			color: $c-black;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
			white-space: nowrap;
		}

		&__td {
			padding: 20px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			white-space: pre;
			vertical-align: top;
		}

		&__empty-state {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		&__text {
			color: $c-dark-grey;
			text-align: center;
		}

		&__edit-button {
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.pagination {
		position: sticky;
		left: 0;
		margin: 10px 0;
		padding: 10px 25px 10px 10px;
		border-radius: 10px;
		background-color: $c-white;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include max-width($scr-sm) {
			flex-direction: column;
		}

		&__nav {
			width: 35px;
			height: 35px;
			padding: 8px;
			border: 0;
			cursor: pointer;
			background-color: transparent;

			&:disabled {
				//
				svg {
					//
					path {
						stroke: darken($c-light-grey, 2%);
					}
				}
			}

			svg {
				//
				path {
					stroke: $c-primary;
				}
			}

			&--prev {
				transform: rotate(90deg);
			}

			&--next {
				transform: rotate(-90deg);
			}
		}

		&__content {
			display: flex;
			align-items: center;
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;

			span {
				color: $c-black;
				font-size: $text-size-xs;
				font-weight: $text-weight-normal;
			}
		}
	}
}
