.unicorn-days {
	/* ---- */
	.days {
		position: relative;
		display: flex;
		align-items: center;

		&:after {
			content: "";
			position: absolute;
			left: 2.5%;
			right: 2.5%;
			bottom: 0;
			height: 1px;
			background-color: $c-unicorn-border;
		}

		@for $i from 0 through 7 {
			&__day:nth-child(#{$i}n) {
				animation-delay: #{$i * 0.05}s;
			}
		}

		&__day {
			width: calc(100% / 7);
			padding: 10px 0;
			opacity: 0;
			transform: scale(0.5) translateY(-25px);
			animation: unicornCellFadeIn 0.5s ease-in forwards;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__text {
			margin: 0;
			color: $c-unicorn-days;
		}

		&__today {
			color: $c-unicorn-orange;
			font-weight: 500;
		}
	}
}
