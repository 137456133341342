.app-layout {
	width: 100%;
	height: 100%;
	min-width: 320px;
	background-color: #0d4892;

	.main {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;

		&__container {
			position: relative;
			width: calc(100% - $side-nav-width);
			max-height: 100vh;
			border-top-left-radius: 25px;
			border-bottom-left-radius: 25px;
			background-color: $c-background;
			transition: $transition;
			display: flex;
			flex-direction: column;

			&--shrinked {
				width: calc(100% - $side-nav-shrinked-width);
			}

			@include max-width($scr-sm) {
				width: 100%;
				z-index: 1298;
				border-radius: 0;
			}
		}

		&__page {
			width: 100%;
			height: 100%;
			max-width: 100%;
			padding: 15px 0 0 15px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__copyright {
			padding: 10px;
			margin: auto 0 0;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-xs;
		}
	}
}
