$c-unicorn-red: #d74747;
$c-unicorn-orange: #ffc607;
$c-unicorn-white: #ffffff;
$c-unicorn-black: #191919;
$c-unicorn-grey: #909090;
$c-unicorn-border: #f0f1fd;
$c-unicorn-days: #909090;
$c-unicorn-selected: #0d4892;
$c-unicorn-offset-date: #bab1ce;
$c-unicorn-text-background: #f9f9f9;

@import "./unicorn-days.scss";
@import "./unicorn-dates.scss";
@import "./unicorn-month.scss";
@import "./unicorn-years.scss";

.unicorn-calendar {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.calendar {
		position: relative;

		&__container {
			width: 100%;
			max-width: 458px;
			margin: 0 auto;
			border-radius: 8px;
			border: 1px solid $c-unicorn-border;
			background-color: $c-unicorn-white;
		}

		&__footer {
			margin: 10px 0;
			gap: 15px;
			display: flex;
			justify-content: flex-end;

			@for $i from 0 through 2 {
				.calendar__button:nth-child(#{$i}n) {
					animation-delay: #{$i * 0.05}s;
				}
			}
		}

		&__button {
			padding: 10px 25px;
			border: 0;
			border-radius: 100px;
			background-color: $c-unicorn-white;
			cursor: pointer;
			opacity: 0;
			transform: translateY(-5px);
			animation: unicornButtonFadeIn 0.5s ease-in forwards;
		}

		&__solid {
			background-color: $c-unicorn-selected;

			&:disabled {
				background-color: $c-unicorn-grey;
				border: 1px solid $c-unicorn-grey;
			}

			.calendar {
				/* ---- */
				&__text {
					color: $c-unicorn-white;
				}
			}
		}

		&__text {
			margin: 0;
			color: $c-unicorn-grey;
		}
	}
}

@keyframes unicornCellFadeIn {
	0% {
		opacity: 0;
		translate: scale(0.5) translateY(-25px);
	}
	85% {
		opacity: 1;
		transform: scale(1.2);
	}
	90% {
		opacity: 1;
		transform: scale(0.9);
	}
	95% {
		opacity: 1;
		transform: scale(1.05);
	}
	100% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
}

@keyframes unicornFadeIn {
	from {
		opacity: 0;
		translate: translateY(-15px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes unicornButtonFadeIn {
	from {
		opacity: 0;
		translate: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
