.app-calendar-input-2 {
	position: relative;
	width: 100%;
	min-width: 150px;
	display: flex;

	.calendar-input-2 {
		width: 100%;

		&--disabled {
			//
			.app-input__label,
			.MuiInputBase-root {
				background-color: #f8f8f8;
			}
		}

		&__label {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__required {
			color: $c-red;
			line-height: 0;
		}

		&__wrapper {
			padding: 0;
			border: 0;
			cursor: pointer;
			background-color: transparent;
			pointer-events: auto;
		}

		&__icon {
			padding: 7px;
			width: 25.5px;
			height: 25.5px;
			pointer-events: auto;
			cursor: pointer;
		}

		.MuiFormControl-root {
			width: 100%;
			pointer-events: none;

			.MuiInputBase-root {
				color: $c-black;
				padding-right: 0;
				border-radius: 0;
				font-weight: $text-weight-normal;
			}

			.MuiInputBase-input {
				padding: 10px 0;
				cursor: pointer;
			}

			.Mui-disabled {
				-webkit-text-fill-color: inherit;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
				border-bottom: 1px solid $c-disabled !important;
			}

			.MuiInputAdornment-root {
				pointer-events: none;
			}

			.MuiFormHelperText-root {
				margin: 3px 0 0;
				color: $c-red;
				font-weight: $text-weight-normal;
				font-size: $text-size-xs;
			}
		}

		&--disabled {
			background-color: #f8f8f8;

			.MuiFormControl-root {
				.Mui-disabled {
					&:not(.MuiFormHelperText-root) {
						-webkit-text-fill-color: rgba(0, 0, 0, 0.38);
					}
				}
			}
		}
	}
}
