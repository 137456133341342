$font-family: Poppins, Roboto, sans-serif;
$side-nav-width: 285px;
$side-nav-shrinked-width: 50px;
$transition: 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

/* -- Color -- */
$c-white: #ffffff;
$c-black: #252525;
$c-dark-grey: #7f7f7f;
$c-grey: #696969;
$c-light-grey: #f8f8f8;
$c-border: #e2e9ef;
$c-background: #f6fbff;
$c-primary: #0d4892;
$c-secondary: #bbbbbb;
$c-tertiary: #2c7fe5;
$c-blue: #0245a9;
$c-green: #00cf9c;
$c-disabled: #d3e0e8;
$c-red: #f64444;
$c-yellow: #ffc770;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 32px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-exbold: 800;
$text-weight-bold: 700;
$text-weight-semibold: 600;
$text-weight-normal: 500;
$text-weight-regular: 400;
$text-weight-thin: 300;
$text-weight-light: 200;
$text-weight-exlight: 100;

/* -- Media query -- */
$scr-xlg: 1366px;
$scr-lg: 1240px;
$scr-md: 1024px;
$scr-sm: 768px;
$scr-xs: 640px;
