.app-invoice-items-modal-table {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	.invoice-items-modal-table {
		width: 100%;
		max-width: 1024px;
		max-height: 560px;
		overflow-y: scroll;
		padding: 20px;
		background-color: $c-white;
		border-radius: 20px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		@include max-width($scr-xs) {
			height: 100%;
			max-height: 100%;
		}

		&__header {
			margin-bottom: 15px;
			display: flex;
			justify-content: flex-end;
		}

		&__close-button {
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;

			svg {
				//
				path {
					stroke: $c-grey;
				}
			}
		}

		&__body {
			//
		}
	}
}
