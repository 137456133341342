.app-invoice-modal {
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.invoice-modal {
		width: 100%;
		max-width: 640px;
		height: 100%;
		margin: 0 0 0 20px;
		background-color: $c-white;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
		display: flex;
		flex-direction: column;

		@include max-width($scr-xs) {
			margin: 0;
			border-radius: 0;
		}

		&__header {
			padding: 35px 35px 0;
			display: flex;
			justify-content: space-between;
		}

		&__headline {
			position: relative;
			margin: 20px 0;
			padding: 0 35px 20px;
			border-bottom: 1px solid $c-border;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__download-button {
			//
			.app-icon {
				width: 20px;
				height: 20px;
				transform: rotate(180deg);
			}
		}

		&__download-progress {
			position: absolute;
			width: 0;
			height: 2px;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $c-green;
			transition: $transition;
		}

		&__wrapper {
			//
			&--row {
				display: flex;
				align-items: center;
			}
		}

		&__edit-button {
			width: 40px;
			height: 40px;
			margin: 0 0 0 10px;
			padding: 0;
			border-radius: 40px;
			border: 1px solid $c-primary;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__body {
			height: 100%;
			padding: 0 35px 35px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__close-button {
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;

			svg {
				//
				path {
					stroke: $c-grey;
				}
			}
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__created-by {
			margin: 0;
			color: $c-secondary;
			font-size: $text-size-xs;
		}

		&__icon {
			width: 20px;
			height: 20px;
			display: block;
			cursor: pointer;
			transition: $transition;
			transform: rotate(90deg);

			svg {
				//
				path {
					stroke: $c-primary;
				}
			}
		}

		&__label {
			margin: 0 0 15px;
			color: $c-tertiary;
			font-weight: $text-weight-semibold;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&--active {
				//
				.invoice-modal__icon {
					transform: rotate(0deg);
				}
			}
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__description {
			margin: 0;
			color: $c-black;
		}

		&__link {
			padding: 0;
			cursor: pointer;
			color: $c-blue;
			text-decoration: underline;
			font-weight: $text-weight-normal;
			background-color: transparent;
			border: 0;
		}

		&__content {
			margin: 0 0 25px;
			padding: 0 0 25px;
			border-bottom: 1px solid $c-border;
			gap: 15px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&--hide {
				display: none;
			}

			@include max-width($scr-xs) {
				flex-direction: column;
			}
		}

		&__item {
			width: calc(50% - 15px);
			list-style-type: none;
		}
	}
}
