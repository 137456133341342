.unicorn-months {
	/* ---- */
	.months {
		position: relative;
		border-top: 1px solid $c-unicorn-border;
		border-bottom: 1px solid $c-unicorn-border;
		display: flex;

		@for $i from 0 through 12 {
			&__month:nth-child(#{$i}n) {
				animation-delay: #{$i * 0.05}s;
			}
		}

		&__month {
			width: calc(100% / 12);
			height: 100%;
			padding: 0;
			border: 0;
			opacity: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			background-color: transparent;
			transform: scale(0.5) translateY(-25px);
			animation: unicornCellFadeIn 0.2s ease-in forwards;
			transition: all 0.3s ease;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&:last-child {
				/* ---- */
				.months {
					/* ---- */
					&__inner {
						border: 0;
					}
				}
			}

			&:disabled {
				cursor: default;

				.months {
					/* ---- */
					&__text {
						position: relative;
						opacity: 0.35;

						&:before {
							content: "";
							position: absolute;
							width: 1px;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin: auto;
							background-color: $c-unicorn-grey;
						}
					}
				}
			}
		}

		&__inner {
			width: 100%;
			height: 100%;
			padding: 15px 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-right: 1px solid $c-unicorn-border;
			transition: all 0.3s ease;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				/* ---- */
				.months {
					/* ---- */
					&__text {
						color: $c-unicorn-black;
						font-weight: 500;

						&:before {
							background-color: $c-unicorn-black;
						}
					}
				}
			}
		}

		&__inner-selected {
			background-color: $c-unicorn-selected;
			border-right: 1px solid $c-unicorn-selected;
			box-shadow: rgba(223, 209, 255, 1) 0px 5px 15px;

			.months {
				/* ---- */
				&__text {
					color: $c-unicorn-white;
					font-weight: 500;
				}
			}

			&:hover {
				transform: scale(1.05);

				.months {
					/* ---- */
					&__text {
						color: $c-unicorn-white;
						font-weight: 500;
					}
				}
			}
		}

		&__text {
			margin: 0;
			color: $c-unicorn-grey;
			white-space: pre;
			text-transform: uppercase;
			transition: all 0.3s ease;
		}

		&__today {
			color: $c-unicorn-orange;
			font-weight: 500;
		}
	}
}
