.app-invoice-bulk-upload-modal {
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	.invoice-bulk-upload-modal {
		width: 100%;
		max-width: 1024px;
		max-height: 560px;
		overflow-y: scroll;
		padding: 20px;
		background-color: $c-white;
		border-radius: 20px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		@include max-width($scr-xs) {
			height: 100%;
			max-height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__header {
			margin-bottom: 15px;
			display: flex;
			justify-content: flex-end;
		}

		&__close-button {
			width: 30px;
			height: 30px;
			border: 0;
			cursor: pointer;
			background-color: transparent;

			svg {
				//
				path {
					stroke: $c-grey;
				}
			}
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__table {
			margin: 20px 0;
		}

		&__body {
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__drop {
			height: 100%;
			min-height: 300px;
			margin: 20px 0;
			padding: 25px;
			border-radius: 8px;
			background-color: $c-white;
			border: 2px dashed $c-border;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			&--dragover {
				border: 2px dashed $c-green;
			}
		}

		&__icon {
			width: 40px;
			height: 40px;
			border-radius: 40px;
			border: 1px dashed $c-green;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__placeholder {
			color: $c-grey;
			font-size: $text-size-s;
		}
	}
}
